import React, { useEffect, useState } from "react";
import SlickSlider from "react-slick";
import { Layout, SliderArtists, Hero, ResponsiveImage } from '../components';
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { getArtworks, getUpcomingDrops, getDrops, formatCCFixed } from "../components/shared/Utils";
import moment from "moment";

// markup
const Home = ({ location }) => {

	var settingsSlick = {
		mobileFirst: true,
		arrows: false,
		dots: true,
		infinite: false,
		variableWidth: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		autoplay: false,
		responsive: [
			{
				breakpoint: 9999,
				settings: {
					slidesToScroll: 2,
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToScroll: 2,
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToScroll: 1,
				}
			}
		]
	};


	var settingsFromDigital = {
		mobileFirst: true,
		arrows: false,
		dots: true,
		infinite: true,
		variableWidth: true,
		slidesToScroll: 1,
		autoplay: false,
		speed: 2000,
		autoPlaySpeed: 2000,
		cssEase: 'ease-out',
		responsive: [
			{
				breakpoint: 9999,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToScroll: 1,
				}
			}
		]
	};


	const [upcomingDrops, setUpcomingDrops] = useState(null);
	const [artworkHighlights, setArtworkHighlights] = useState(null);
	/* const [latestDrops, setLatestDrops] = useState(null); */

	useEffect(() => {
		getUpcomingDrops(setUpcomingDrops);
		getArtworks(setArtworkHighlights, { limit: 10, order_by: 'r' });
		/* getDrops(setLatestDrops, { limit: 2, order_by: 'd', order_direction: 'desc', asset_limit: 3, asset_order_by: 'r' }); */
	}, []);


	return (
		<Layout location={location}>

			<Helmet>
				<link href={'/assets/css/index.css'} rel={'stylesheet'} type={'text/css'} />
			</Helmet>

			<Hero
				isHero={true}
			// hasDrops={true}
			/>

			<main data-name={'layout'} className={'py-5'}>
				<div className={'main-wrapper'}>


					<div className={'social-wrapper d-none d-lg-block container-fluid'}>
						<ul>
							<li className={'social-networks'}>
								<a href={'https://discord.gg/dtPAJHRfye'} target={'_blank'} rel={'noreferrer'}>
									<img src={'/assets/img/icons/discord_icon.svg'} alt={'Discord'} />
								</a>
								<a href={'https://twitter.com/EphemeralEther1'} target={'_blank'} rel={'noreferrer'}>
									<img src={'/assets/img/icons/twitter_icon.svg'} alt={'Twitter'} />
								</a>
								<a href={'https://www.instagram.com/ephemeralethernal/'} target={'_blank'} rel={'noreferrer'}>
									<img src={'/assets/img/icons/instagram_icon.svg'} alt={'Instagram'} />
								</a>
							</li>
						</ul>
					</div>


					{upcomingDrops ? (
						<div className={'upcoming-drops container-fluid justify-content-center align-items-center flex-column'}>
							<div className={'upcoming-info'}>
								<div className={'drops-qty'}><span>{upcomingDrops.c}</span></div>
								<Link to={'/#upcoming_drops'}>
									Upcoming Drop{upcomingDrops.c === 1 ? '' : 's'}
								</Link>
							</div>
						</div>
					) : null}

					<a id={'upcoming_drops'} name={'upcoming_drops'} className={'anchor'}></a>

					{upcomingDrops ? (
						<>
							<div className={'upcoming-container'}>
								<div className={'container-fluid bg-container'}>
									<div className={'slider-bg'}></div>
								</div>

								<SlickSlider {...settingsSlick} dots={true} className={'slider upcoming-drops-slider'}>


									<div className={'slide upcoming-info'}>
										<div className={'drops-qty'}>
											<span>{upcomingDrops.c}</span>
										</div>
										<span>Upco<br />ming<br />Drop{upcomingDrops.c === 1 ? '' : 's'}</span>
										<div className={'upcoming-text'}>
											Clearing the way towards the end of ephemeral art!<br />
											Join our community and gain access to special pre-sale periods and other perks.
										</div>
									</div>

									{upcomingDrops.i.map((drop, drop_index) => {
										return drop.artists.map((artist, artist_index) => {
											return (
												<div className={'slide'} key={'slide_' + drop_index + '_' + artist_index}>
													<div className={'artwork-img'}>
														<div className={'drop-countdown flex-column d-flex justify-content-center'}>
															<div className={''}>Drops in</div>
															<div className={'d-flex justify-content-between'}>
																<ul className={'d-flex flex-column justify-content-between align-items-center'}>
																	<li className={'countdown'}>{drop.time_left.days}</li>
																	<li className={'countdown-label'}>Days</li>
																</ul>
																<ul className={'countdown-divisor'}>
																	<li>:</li>
																</ul>
																<ul className={'d-flex flex-column justify-content-between align-items-center'}>
																	<li className={'countdown'}>{drop.time_left.hours}</li>
																	<li className={'countdown-label'}>Hours</li>
																</ul>
																<ul className={'countdown-divisor'}>
																	<li>:</li>
																</ul>
																<ul className={'d-flex flex-column justify-content-between align-items-center'}>
																	<li className={'countdown'}>{drop.time_left.minutes}</li>
																	<li className={'countdown-label'}>Minutes</li>
																</ul>
															</div>
														</div>

														<ResponsiveImage
															initialSrc={artist.preview_card_paths['275']}
															initialSrcSet={`${artist.preview_card_paths['275']} 1x, ${artist.preview_card_paths['550']} 2x`}
															alt={drop.name}
															sources={[
																{
																	srcset: [
																		{
																			src: artist.preview_card_paths['275_webp'],
																			xd: "1x",
																		},
																		{
																			src: artist.preview_card_paths['550_webp'],
																			xd: "2x",
																		}
																	],
																},
																{
																	srcset: [
																		{
																			src: artist.preview_card_paths['275'],
																			xd: "1x",
																		},
																		{
																			src: artist.preview_card_paths['550'],
																			xd: "2x",
																		}
																	],
																}
															]}
														/>
													</div>

													<Link to={`/artists/detail/?${artist.slug}`} className={'artwork-artist'}>
														<div className={'avatar'}>
															{artist.avatar_paths ? (
																<ResponsiveImage
																	initialSrc={artist.avatar_paths['24']}
																	initialSrcSet={`${artist.avatar_paths['24']} 1x, ${artist.avatar_paths['48']} 2x`}
																	alt={'Artist photo'}
																	sources={[
																		{
																			srcset: [
																				{
																					src: artist.avatar_paths['24_webp'],
																					xd: "1x",
																				},
																				{
																					src: artist.avatar_paths['48_webp'],
																					xd: "2x",
																				}
																			],
																		},
																		{
																			srcset: [
																				{
																					src: artist.avatar_paths['24'],
																					xd: "1x",
																				},
																				{
																					src: artist.avatar_paths['48'],
																					xd: "2x",
																				}
																			],
																		}
																	]}
																/>
															) : (
																artist.name.substr(0, 1)
															)}
														</div>
														<div className={'name'}>{artist.name}</div>
													</Link>

													<div className={'auction d-flex '}>
														<div className={'bid-start w-50 d-flex flex-column justify-content-between'}>
															<div>bid stars at</div>
															<div className={'d-flex align-items-center'}>
																<span>{formatCCFixed(drop.launch_price, 18, 4).replace('.', ',')}</span>
																<span>{artist.payment_symbol}</span>
															</div>
														</div>

														<div className={'release-date w-50 d-flex flex-column justify-content-between'}>
															<div>Release Date</div>
															<div className={'date'}>
																<div>{drop.drop_launch_date}</div>
																<div>{drop.drop_launch_time}</div>
															</div>
														</div>
													</div>
												</div>
											);
										});
									})}
								</SlickSlider>
							</div>
						</>
					) : (
						upcomingDrops == null ? (
							<div className={'content-placeholder d-flex justify-content-center align-items-center my-5'}>
								<div className={'container-fluid'}>
									<div className={'gradient py-5 text-center col-12'}>
										<span style={{ color: '#999' }}>Checking for Upcoming Drops...</span>
									</div>
								</div>
							</div>
						) : (null)
					)}



					{!artworkHighlights || artworkHighlights.t > 0 ?
						<>
							<div className={'latest-art-container'}>
								<div className={'container-fluid col-10 col-md-12 col-lg-10 d-flex justify-content-between align-items-end'}>
									<div className={'slider-title'}>Artwork<br />Highlights</div>
									<div className={'browse-all d-none d-md-flex'}>
										<Link to={'/artworks/'}>
											Our collections
										</Link>
										<div>{artworkHighlights ? artworkHighlights.t : '...'}</div>
									</div>
								</div>

								{artworkHighlights ? (

									<SlickSlider {...settingsSlick} className={'slider latest-art'}>

										{artworkHighlights.i.map((artwork, artwork_index) => {
											return (
												<div className={'slide my-5'} key={'slide_' + artwork_index}>
													<div className={'artwork-img'}>
														<a href={artwork.permalink} target={'_blank'} rel={'noreferrer'}>
															<div className={'open-hover-fx'}>Open</div>
															<ResponsiveImage
																initialSrc={artwork.image_preview_url}
																initialSrcSet={`${artwork.image_preview_url} 1x, ${artwork.image_url} 2x`}
																alt={"Artwork image"}
															/>
														</a>
													</div>

													<div className={'artwork-title'}>{artwork.short_name}</div>
													<div className={'artwork-description'}></div>
													<Link to={`/artists/detail/?${artwork.artist.slug}`} className={'artwork-artist'}>
														<div className={'avatar'}>
															{artwork.artist.avatar_paths ? (
																<ResponsiveImage
																	initialSrc={artwork.artist.avatar_paths['24']}
																	initialSrcSet={`${artwork.artist.avatar_paths['24']} 1x, ${artwork.artist.avatar_paths['48']} 2x`}
																	alt={'Artist photo'}
																	sources={[
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24_webp'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48_webp'],
																					xd: "2x",
																				}
																			],
																		},
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48'],
																					xd: "2x",
																				}
																			],
																		}
																	]}
																/>
															) : (
																artwork.artist.name.substr(0, 1)
															)}
														</div>
														<div className={'name'}>{artwork.artist.name}</div>
													</Link>

													{/* 	<div className={'artwork-artist'}>
														<div className={'avatar'}>
															{artwork.artist.avatar_paths ? (
																<ResponsiveImage
																	initialSrc={artwork.artist.avatar_paths['24']}
																	initialSrcSet={`${artwork.artist.avatar_paths['24']} 1x, ${artwork.artist.avatar_paths['48']} 2x`}
																	alt={'Artist photo'}
																	sources={[
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24_webp'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48_webp'],
																					xd: "2x",
																				}
																			],
																		},
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48'],
																					xd: "2x",
																				}
																			],
																		}
																	]}
																/>
															) : (
																artwork.artist.name.substr(0, 1)
															)}
														</div>
														<div className={'name'}>{artwork.artist.name}</div>
													</div> */}
													<div className={'artwork-auction'}>
														{artwork.last_price ? (<div>last price</div>) : null}
														<div className={'bid'}>
															<div>
																{artwork.last_price ? (
																	<>
																		<div className={'fixed-with-gradient'}>{formatCCFixed(artwork.last_price, artwork.last_payment_token.decimals, 4).replace('.', ',')}</div>
																		<div>{artwork.last_payment_token.symbol}</div>
																	</>
																) : (
																	<a href={artwork.permalink} target={'_blank'} rel={'noreferrer'} className={'not-on-sale'}>make an offer</a>
																)}
															</div>
															<a href={artwork.permalink} target={'_blank'} rel={'noreferrer'} className={'btn btn--secondary'}>OpenSea</a>
														</div>
													</div>
												</div>
											);
										})}

									</SlickSlider>

								) : (

									<SlickSlider {...settingsSlick} className={'slider latest-art'}>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}></div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}>&nbsp;</div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}>&nbsp;</div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}>&nbsp;</div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}>&nbsp;</div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

										<div className={'content-placeholder slide my-5'}>
											<div className={'card w-100'}>
												<div className={'dummy-img gradient'}>&nbsp;</div>
												<div className={'dummy-title gradient'}>&nbsp;</div>
												<div className={'dummy-description gradient'}>&nbsp;</div>
												<div className={'dummy-artist'}>
													<div className={'circle gradient'}>&nbsp;</div>
													<div className={'artist-name gradient'}>&nbsp;</div>
												</div>
											</div>
										</div>

									</SlickSlider>
								)}

							</div>

							<div className={'browse-all d-flex d-md-none'}>
								<Link to={'/our-collections/'}>
									Our collections
									<div>{artworkHighlights ? artworkHighlights.t : '...'}</div>
								</Link>
							</div>
						</>
						: null}





					<SlickSlider {...settingsFromDigital} className={'slider from-digital my-5'}>

						<div className={'slide'}>
							<div className={'slide-container'}>

								<div className={'d-flex align-items-center'}>
									<div className={'title-wrap d-flex align-items-top'}>
										<div className={'title-num'}>
											<span>1</span>
										</div>
										<div className={'title'}>From<br />Ephe<br />meral</div>
									</div>
									<div className={'slide-text'}>
										We are a web3 platform for high-end curated drops and projects, articulating exceptionally poignant and unique creative voices plunging into the future and questioning the structures that support our concept and experience of modernity.
									</div>
								</div>

							</div>
						</div>

						<div className={'slide'}>
							<div className={'slide-container'}>

								<div className={'d-flex align-items-center'}>
									<div className={'title-wrap d-flex align-items-top'}>
										<div className={'title-num'}>
											<span>2</span>
										</div>
										<div className={'title'}>To<br />Eth<br />ernal</div>
									</div>
									<div className={'slide-text'}>
										We embody the spirit of this new technology, clearing the way towards the end of ephemeral art, elevating it into what was once as the ether: weightless, transparent, frictionless, undetectable chemically or physically, and literally permeating all matter and space.
									</div>
								</div>

							</div>
						</div>
					</SlickSlider>







					{/* <div className={'last-drops-bg'}>
						<div className={'last-drops-container'}>
							<div className={'container-fluid text-end text-md-start d-md-flex justify-content-between align-items-center col-12 col-lg-10'}>
								<div className={'slider-title'}>Latest<br />Drops</div>
								<div className={'browse-all d-none d-md-flex'}>
									<Link to={'/drops/'}>
										Browse All Drops
									</Link>
								</div>
							</div>



							{latestDrops ? (

								<SlickSlider {...settingsSlick} className={'slider last-drops'}>

									{latestDrops.i.map((drop) => {
										let dropSlides = [];
										dropSlides.push(
											<div className={'slide drop mt-5'}>
												<div className={'drop-info'}>
													<div>Drop<br />#{(drop.number < 10 ? '0' : '') + drop.number}</div>
													<div>{moment.utc(drop.drop_launch).format('DD MMM, YYYY')}</div>
												</div>
											</div>
										);
										return dropSlides.concat([

											drop.nfts.map((nft) => {
												return (
													<div className={'slide my-5'}>
														<div className={'last-drop-banner'}><strong>Drop #{(drop.number < 10 ? '0' : '') + drop.number}</strong>&nbsp;/&nbsp;{moment.utc(drop.drop_launch).format('DD MMM, YYYY')}</div>
														<div className={'artwork-img'}>
															<a href={nft.permalink} target={'_blank'} rel={'noreferrer'}>
																<div className={'open-hover-fx'}>Open</div>
																<ResponsiveImage
																	initialSrc={nft.image_preview_url}
																	initialSrcSet={`${nft.image_preview_url} 1x, ${nft.image_url} 2x`}
																	alt={'Artwork photo'}
																/>
															</a>
														</div>

														<div className={'artwork-title'}>{nft.short_name}</div>
														<div className={'artwork-description'}>{nft.description}</div>

														<Link to={`/artists/detail/?${nft.artist.slug}`} className={'artwork-artist'}>
															<div className={'avatar'}>
																{nft.artist.avatar_paths ? (
																	<ResponsiveImage
																		initialSrc={nft.artist.avatar_paths['24']}
																		initialSrcSet={`${nft.artist.avatar_paths['24']} 1x, ${nft.artist.avatar_paths['48']} 2x`}
																		alt={'Artist photo'}
																		sources={[
																			{
																				srcset: [
																					{
																						src: nft.artist.avatar_paths['24_webp'],
																						xd: "1x",
																					},
																					{
																						src: nft.artist.avatar_paths['48_webp'],
																						xd: "2x",
																					}
																				],
																			},
																			{
																				srcset: [
																					{
																						src: nft.artist.avatar_paths['24'],
																						xd: "1x",
																					},
																					{
																						src: nft.artist.avatar_paths['48'],
																						xd: "2x",
																					}
																				],
																			}
																		]}
																	/>
																) : (
																	nft.artist.name.substr(0, 1)
																)}
															</div>
															<div className={'name'}>{nft.artist.name}</div>
														</Link>

														<div className={'artwork-auction'}>
															{nft.last_price ? (<div>last price</div>) : null}
															<div className={'bid'}>
																<div>
																	{nft.last_price ? (
																		<>
																			<div className={'fixed-with-gradient'}>{formatCCFixed(nft.last_price, nft.last_payment_token.decimals, 4).replace('.', ',')}</div>
																			<div>{nft.last_payment_token.symbol}</div>
																		</>
																	) : (
																		<a href={nft.permalink} target={'_blank'} rel={'noreferrer'} className={'not-on-sale'}>make an offer</a>
																	)}
																</div>
																<a href={nft.permalink} target={'_blank'} rel={'noreferrer'} className={'btn btn--secondary'}>OpenSea</a>
															</div>
														</div>
													</div>
												);

											}),
											<div className={'slide my-5'}>
												<div className={'more-from-drop'}>
													<Link to={`/drops/?drop=${drop.slug}`}>
														<div>
															<div className={'dot'}></div>
															<div className={'dot'}></div>
															<div className={'dot'}></div>
														</div>
														<div>More from<br />Drop #{(drop.number < 10 ? '0' : '') + drop.number}<span>&nbsp;/&nbsp;{moment.utc(drop.drop_launch).format('DD MMM, YYYY')}</span></div>
													</Link>
												</div>
											</div>

										]);

									})}


								</SlickSlider>

							) : (

								<SlickSlider {...settingsSlick} className={'slider last-drops'}>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}></div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}>&nbsp;</div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}>&nbsp;</div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}>&nbsp;</div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}>&nbsp;</div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

								<div className={'content-placeholder slide my-5'}>
									<div className={'card w-100'}>
										<div className={'dummy-img gradient'}>&nbsp;</div>
										<div className={'dummy-title gradient'}>&nbsp;</div>
										<div className={'dummy-description gradient'}>&nbsp;</div>
										<div className={'dummy-artist'}>
											<div className={'circle gradient'}>&nbsp;</div>
											<div className={'artist-name gradient'}>&nbsp;</div>
										</div>
									</div>
								</div>

							</SlickSlider>

							)}






							<div className={'browse-all d-flex d-md-none'}>
								<Link to={'/drops/'}>
									Browse All Drops
								</Link>
							</div>
						</div>

					</div> */}


				</div>


				{/* <SliderArtists showTitle={true}>
					<div className={'im-an-artist'}></div>
				</SliderArtists> */}

				<div className={'main-wrapper'}>
					<div className={'artists-container'}>
						<div className={'container-fluid col-10 col-md-12 col-lg-10 d-flex justify-content-between align-items-end'}>
							<div className={'slider-title'}>Artists</div>
						</div>
						<SliderArtists />
					</div>
				</div>


				<div className={'group-brands container-fluid'}>

					<div className={'row pt-5 pb-3 justify-content-center align-items-center'}>
						<div className={'col-12 col-xxl-2 text-center'}>
							<h4>Ephemeral Ethernal is a part of</h4>
						</div>
						<div className={'col-6 col-md-3 col-xxl-2 d-none d-xxl-block'}>&nbsp;</div>
						<div className={'col-6 col-md-3 col-xxl-2 d-none d-xxl-block'}>&nbsp;</div>
						<div className={'col-6 col-md-3 col-xxl-2 d-none d-xxl-block'}>&nbsp;</div>
						<div className={'col-6 col-md-3 col-xxl-2 d-none d-xxl-block'}>&nbsp;</div>
					</div>

					<div className={'row pb-5 justify-content-center align-items-center'}>

						<div className={'col-12 col-xxl-2 text-center pb-5 p-xxl-5 pt-xxl-0'}>
							<a href={'https://cultural-affairs.com/'} target={'_blank'} rel={'noopener'}>
								<img className={'img logo-ca'} src={'/assets/img/logo/logo-ca.svg'} alt={'Cultural Affairs'} />
							</a>
						</div>

						<div className={'col-6 col-md-3 col-xxl-2 text-center p-4 p-lg-5 pb-5 pt-xxl-0'}>
							<a href={'https://vhils.com'} target={'_blank'} rel={'noopener'}>
								<img className={'img logo-v'} src={'/assets/img/logo/logo-v.svg'} alt={'VHILS'} />
							</a>
						</div>

						<div className={'col-6 col-md-3 col-xxl-2 text-center p-4 p-lg-5 pb-5 pt-xxl-0'}>
							<a href={'https://www.under-dogs.net'} target={'_blank'} rel={'noopener'}>
								<img className={'img logo-u'} src={'/assets/img/logo/logo-u.svg'} alt={'Underdogs'} />
							</a>
						</div>

						<div className={'col-6 col-md-3 col-xxl-2 text-center p-4 p-lg-5 pb-5 pt-xxl-0'}>
							<a href={'https://www.iminente.org'} target={'_blank'} rel={'noopener'}>
								<img className={'img logo-i'} src={'/assets/img/logo/logo-i.svg'} alt={'Iminente'} />
							</a>
						</div>

						<div className={'col-6 col-md-3 col-xxl-2 text-center p-4 p-lg-5 pb-5 pt-xxl-0'}>
							<img className={'img logo-ca'} src={'/assets/img/logo/logo-ee.svg'} alt={'Ephemeral | Ethernal'} />
						</div>
					</div>
				</div>


			</main>
		</Layout>
	)
}

export default Home
